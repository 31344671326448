<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Uživatelé - Administrátoři - seznam</strong>
      </div>
      <action-tools :router-options="routerOptions" :handle-refresh="handleRefresh"></action-tools>
    </div>
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-md-6">
          <a-dropdown :placement="'bottomLeft'">
            <a-button>Hromadné akce
              <a-icon type="down"/>
            </a-button>
            <a-menu slot="overlay">
              <a-menu-item :disabled="selectedRowKeys.length === 0" :class="{'disabled': selectedRowKeys.length === 0}">
                <a-popconfirm @confirm="confirmDelete(selectedRowKeys)" okText="Potvrdit" cancelText="Zrušit">
                  <template slot="title">
                    <p>Opravdu si přejete odstranit tyto položky?</p>
                    <div v-if="selectedRowKeys.length > 0">
                      {{ selectedRowKeys }}
                    </div>
                  </template>
                  Odstranit
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <div class="text-right col-md-6">
          <a-input-search
            placeholder="Hledat..."
            style="width: 200px"
            @search="handleSearch"
          />
        </div>
      </div>
      <a-table
        tableLayout="auto"
        :rowSelection="rowSelection"
        :rowKey="record => record.uer_id"
        :columns="columns"
        :loading="loading"
        :dataSource="userAdmin"
        class="utils__scrollTable"
        :scroll="{ x: '100%' }"
      >
        <router-link
          slot="uer_id"
          slot-scope="value"
          class="utils__link--underlined"
          :to="'/user/admin/detail/' + value"
        >#<span v-if="searchText">
            <template
              v-for="(fragment, i) in value.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{value}}</template>
        </router-link>
        <template slot="customRender" slot-scope="text">
          <span v-if="searchText">
            <template
              v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{text}}</template>
        </template>
        <span slot="avatar" slot-scope="text">
          <cui-avatar v-if="text !== null && text.length > 0" :src="$avatarVectorPath + text" :size="50" :svg="true"/>
          <a-avatar v-else icon="user" :size="50"/>
          </span>
        <span slot="action" slot-scope="record">
          <router-link :to="'/user/admin/detail/'+record.uer_id">
            <a-button icon="search" size="small" class="mr-1">Zobrazit</a-button>
          </router-link>
          <router-link v-if="user.roles && Array.isArray(user.roles) && user.roles.find(x => x.name === 'SUPER_ADMIN') !== undefined" :to="'/user/admin/edit/'+record.uer_id">
            <a-button icon="edit" size="small" class="mr-1">Upravit</a-button>
          </router-link>
          <a-popconfirm @confirm="confirmDelete([record.uer_id])" okText="Potvrdit" cancelText="Zrušit">
            <template slot="title">
              <p>Opravdu si přejete odstranit položku: #{{ record.uer_id }} ?</p>
            </template>
            <a-button icon="delete" size="small">Odstranit</a-button>
          </a-popconfirm>
        </span>
        <template slot="footer">
          {{ userAdmin.length }} položek
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import ActionTools from '@/components/MyComponents/ActionTools'
import CuiAvatar from '@/components/CleanUIComponents/Avatar'

export default {
  components: { ActionTools, CuiAvatar },
  data: function () {
    return {
      searchText: '',
      loading: false,
      selectedRowKeys: [],
      routerOptions: [
        {
          icon: 'plus-circle',
          to: '/user/admin/add',
          title: 'Přidat',
        },
      ],
      columns: [
        {
          title: 'ID',
          dataIndex: 'uer_id',
          defaultSortOrder: 'ascend',
          sorter: (a, b) => a.uer_id - b.uer_id,
          scopedSlots: {
            customRender: 'uer_id',
          },
        },
        {
          title: 'Ikonka',
          dataIndex: 'avatar',
          scopedSlots: {
            customRender: 'avatar',
          },
        },
        {
          title: 'E-mail',
          dataIndex: 'email',
          sorter: (a, b) => a.email.localeCompare(b.email),
          scopedSlots: {
            customRender: 'customRender',
          },
        },
        {
          title: 'Jméno',
          dataIndex: 'uer_first_name',
          sorter: (a, b) => a.uer_first_name.localeCompare(b.uer_first_name),
          scopedSlots: {
            customRender: 'customRender',
          },
        },
        {
          title: 'Příjmení',
          dataIndex: 'uer_last_name',
          sorter: (a, b) => a.uer_last_name.localeCompare(b.uer_last_name),
          scopedSlots: {
            customRender: 'customRender',
          },
        },
        {
          title: 'Akce',
          key: 'action',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
    }
  },
  computed: {
    user: function () {
      return this.$store.getters['auth/getItem']
    },
    userAdmin: function () {
      return this.$store.getters['userAdmin/filteredItems'](this.searchText)
    },
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: [{
          key: 'all-data',
          text: 'Vybrat všechny položky',
          onSelect: () => {
            if (this.selectedRowKeys.length === 0) {
              this.userAdmin.forEach(item => {
                if (item.children !== undefined) {
                  this.selectedRowKeys = this.selectedRowKeys.concat(item.uer_id).concat(item.children.map(x => x.uer_id))
                } else {
                  this.selectedRowKeys.push(item.uer_id)
                }
              })
            } else {
              this.selectedRowKeys = []
            }
          },
        }],
        onSelection: this.onSelection,
      }
    },
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleRefresh() {
      this.loading = true
      this.$store.dispatch('userAdmin/getList')
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    confirmDelete(userAdmin) {
      this.loading = true
      this.$store.dispatch('userAdmin/delete', userAdmin)
        .then(() => {
          if (this.selectedRowKeys.length > 0) {
            this.selectedRowKeys = userAdmin.filter(x => !this.selectedRowKeys.includes(x))
              .concat(this.selectedRowKeys.filter(x => !userAdmin.includes(x)))
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    handleSearch(searchText) {
      this.searchText = searchText
    },
  },
  created() {
    if (this.userAdmin.length === 0) {
      this.handleRefresh()
    }
  },
}
</script>

<style lang="scss" module scoped>

</style>
